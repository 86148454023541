import { connect } from 'react-redux';
import { get, keyBy } from 'lodash';
import i18next from 'i18next';
import moment from 'moment';
import React, { Component } from 'react';

import {
  deleteMultipleStoreSupplierProductMapping,
  getStoreSupplierProductMappingsOfStore,
  receiveDeleteMultipleStoreSupplierProductMapping,
  receiveStoreSupplierProductMappings,
  requestDeleteMultipleStoreSupplierProductMappingError,
  requestStoreSupplierProductMappingsError,
} from '@actions/storeSupplierProductMapping';
import { getSuppliersOfClient, receiveSuppliers, requestSuppliersError } from '@actions/supplier';
import { showConfirmationMessage } from '@actions/messageconfirmation';

import { Button, ListView } from '@commons/utils/styledLibraryComponents';
import { DATE_DISPLAY_FORMATS } from '@commons/DatePickers/constants';
import { InpulseLabel } from '@commons/DeepsightComponents';
import { sortArrayOfObjectsAlphabetically } from '@commons/utils/sorting';
import DisplayNumber from '@commons/DisplayNumber';
import utilsXLS from '@commons/utils/makeXLS';

import { ENUM_QUERY_PARAMS, withListViewQueryParamHookHOC } from '@hooks/useListViewQueryParams';

import { canUpdateAssociatedSupplier } from '@selectors/user';
import { getClientInfo } from '@selectors/client';

import { storeSupplierProductMapping } from '@services/storeSupplierProductMapping';

import { Text } from './styledComponents';
import DeepsightFiltersButton from '@admin/components/FilterButton';
import EmptyState from '@admin/suppliers/suppliers/components/EmptyState';

moment.locale('fr');

const STATUS = [
  { id: 'active', name: 'Oui', color: 'green-1', inpulseColor: 'green' },
  { id: 'inactive', name: 'Non', color: 'red', inpulseColor: 'red' },
];

export class StoreSupplierProductMapping extends Component {
  constructor(props) {
    super(props);
    this.state = {
      storeSupplierProductMappings: [],
      selectedSupplierProducts: [],
      filteredSupplierProductsList: [],
      isLoading: true,
      vueTitle: i18next.t('ADMIN.STORES.LIST_SHEET_TITLE_ENTITIES'),
      vueExportName: i18next.t('ADMIN.STORES.LIST_SHEET_TITLE_ENTITIES'),
      storeName: '',
      dataTitles: [
        {
          id: 'name',
          baseName: 'name',
          propertyKey: 'name',
          name: i18next.t('ADMIN.STORES.LIST_COLUMN_NAME'),
          displayName: i18next.t('ADMIN.STORES.LIST_COLUMN_NAME'),
          render: (it) => <Text highlighted>{it}</Text>,
        },
        {
          id: 'category',
          baseName: 'category',
          propertyKey: 'category',
          name: i18next.t('ADMIN.STORES.LIST_COLUMN_CATEGORY'),
          displayName: i18next.t('ADMIN.STORES.LIST_COLUMN_CATEGORY'),
          filterType: 'string',
          filterStringAllowNone: true,
          render: (item) => this.renderText(item),
        },
        {
          id: 'subCategory',
          baseName: 'subCategory',
          propertyKey: 'subCategory',
          name: i18next.t('ADMIN.STORES.LIST_COLUMN_SUB_CATEGORY'),
          displayName: i18next.t('ADMIN.STORES.LIST_COLUMN_SUB_CATEGORY'),
          filterType: 'string',
          excludeFromSearch: true,
          filterStringAllowNone: true,
          render: (item) => this.renderText(item),
        },
        {
          id: 'supplierName',
          baseName: 'supplierName',
          propertyKey: 'supplierName',
          name: i18next.t('ADMIN.STORES.LIST_COLUMN_SUPPLIER'),
          displayName: i18next.t('ADMIN.STORES.LIST_COLUMN_SUPPLIER'),
          filterType: 'string',
          excludeFromSearch: true,
          filterStringAllowNone: true,
          render: (item) => this.renderText(item),
        },
        {
          id: 'price',
          baseName: 'price',
          propertyKey: 'price',
          type: 'currency',
          name: i18next.t('ADMIN.STORES.LIST_COLUMN_PRICE_TAX_EXCLUDED'),
          displayName: i18next.t('ADMIN.STORES.LIST_COLUMN_PRICE_TAX_EXCLUDED'),
          filterType: 'numeric',
          excludeFromSearch: true,
          render: (item) => <DisplayNumber displayCurrencyCode={true} number={item} />,
        },
        {
          id: 'availability',
          baseName: 'availability',
          propertyKey: 'availability',
          name: i18next.t('ADMIN.STORES.LIST_COLUMN_AVAILABILITY'),
          displayName: i18next.t('ADMIN.STORES.LIST_COLUMN_AVAILABILITY'),
          excludeFromSearch: true,
          render: (item) => this.renderLabel(item, 0),
        },
        {
          id: 'hasStockOrder',
          baseName: 'hasStockOrder',
          propertyKey: 'hasStockOrder',
          name: i18next.t('ADMIN.STORES.LIST_COLUMN_STOCKS'),
          displayName: i18next.t('ADMIN.STORES.LIST_COLUMN_STOCKS'),
          excludeFromSearch: true,
          render: this.renderLabel,
        },
        {
          id: 'hasDlcLoss',
          baseName: 'hasDlcLoss',
          propertyKey: 'hasDlcLoss',
          name: i18next.t('ADMIN.STORES.LIST_COLUMN_LOSS'),
          displayName: i18next.t('ADMIN.STORES.LIST_COLUMN_LOSS'),
          excludeFromSearch: true,
          render: this.renderLabel,
        },
      ],
      actions: [],
      filters: null,
      applyFilters: true,
      advancedFilters: null,
      columnsFilterList: [],
      choicesForAvailabilityDropdown: [
        {
          id: 1,
          name: i18next.t('ADMIN.STORES.ORDER_AVAILABLE'),
          itemValue: true,
        },
        {
          id: 2,
          name: i18next.t('ADMIN.STORES.ORDER_NOT_AVAILABLE'),
          itemValue: false,
        },
      ],
      activeKeysForAvailabilityDropdown: [
        { id: 1, name: i18next.t('ADMIN.STORES.ORDER_AVAILABLE'), itemValue: true },
        { id: 2, name: i18next.t('ADMIN.STORES.ORDER_NOT_AVAILABLE'), itemValue: false },
      ],
      choicesForStockDropdown: [
        {
          id: 1,
          name: i18next.t('ADMIN.STORES.IN_STOCK'),
          itemValue: true,
        },
        {
          id: 2,
          name: i18next.t('ADMIN.STORES.NOT_IN_STOCK'),
          itemValue: false,
        },
      ],
      activeKeysForStockDropdown: [
        { id: 1, name: i18next.t('ADMIN.STORES.IN_STOCK'), itemValue: true },
        { id: 2, name: i18next.t('ADMIN.STORES.NOT_IN_STOCK'), itemValue: false },
      ],
      choicesForDlcLossDropdown: [
        {
          id: 1,
          name: i18next.t('ADMIN.STORES.WITH_LOSSES'),
          itemValue: true,
        },
        {
          id: 2,
          name: i18next.t('ADMIN.STORES.WITHOUT_LOSSES'),
          itemValue: false,
        },
      ],
      activeKeysForDlcLossDropdown: [
        { id: 1, name: i18next.t('ADMIN.STORES.WITH_LOSSES'), itemValue: true },
        { id: 2, name: i18next.t('ADMIN.STORES.WITHOUT_LOSSES'), itemValue: false },
      ],
    };
  }

  renderText = (item) => <Text>{item}</Text>;

  handleExport = (data) => {
    const { currency } = this.props;

    const { vueTitle, vueExportName, dataTitles } = this.state;

    const {
      storeParams: { name: storeName },
    } = this.props;

    const sheetName = `${vueExportName}_${storeName}`;

    const sheet = utilsXLS.generateDefaultSheet(sheetName, dataTitles, data, currency);

    utilsXLS.makeXLS(vueTitle, [sheet]);
  };

  updateListActions = () => {
    const suppliersKeyById = keyBy(this.props.suppliers, 'id');

    const userHasRightToUpdateAllSelectedItems = this.state.selectedSupplierProducts.every(
      ({ supplierId }) =>
        canUpdateAssociatedSupplier(
          this.props.user,
          suppliersKeyById[supplierId],
          this.props.client,
        ),
    );

    const actions = [
      {
        id: 'export',
        displayActionInLine: true,
        actionLabel: i18next.t('GENERAL.EXPORT'),
        handleAction: () => this.handleExport(this.state.filteredSupplierProductsList),
        render: this.renderExport,
      },
    ];
    if (!this.state.selectedSupplierProducts.length) {
      actions.push({
        id: 'associate',
        displayActionInLine: true,
        actionLabel: i18next.t('GENERAL.ASSOCIATE'),
        handleAction: () =>
          this.props.history.push({
            pathname: '/admin/stores/' + this.state.storeId + '/supplier-products/create',
          }),
        render: this.renderAssociate,
      });
      return this.setState({ actions });
    } else if (this.state.selectedSupplierProducts.length === 1) {
      const isOrderAvailable =
        this.state.selectedSupplierProducts[0].availability === 'Oui' ? true : false;
      const withStock =
        this.state.selectedSupplierProducts[0].hasStockOrder === 'Oui' ? true : false;
      const withLoss = this.state.selectedSupplierProducts[0].hasDlcLoss === 'Oui' ? true : false;
      actions.push(
        {
          id: 'delete',
          displayActionInLine: userHasRightToUpdateAllSelectedItems,
          actionLabel: i18next.t('GENERAL.DELETE'),
          handleAction: () => this.deleteItem(this.state.selectedSupplierProducts),
          render: this.renderDelete,
          isHidden: () => !userHasRightToUpdateAllSelectedItems,
        },
        {
          id: 'order-available',
          displayActionInLine: false,
          actionLabel:
            isOrderAvailable === false
              ? i18next.t('ADMIN.STORES.ORDER_SET_AVAILABLE_ACTION')
              : i18next.t('ADMIN.STORES.ORDER_SET_NOT_AVAILABLE_ACTION'),
          actionIcon: () => '/images/inpulse/power-black-small.svg',
          handleAction: () =>
            this.setItemsValue(this.state.selectedSupplierProducts, 'available', !isOrderAvailable),
        },
        {
          id: 'add-remove-stock',
          displayActionInLine: false,
          actionLabel:
            withStock === false
              ? i18next.t('ADMIN.STORES.ADD_TO_STOCK_ACTION')
              : i18next.t('ADMIN.STORES.REMOVE_FROM_STOCK_ACTION'),
          actionIcon:
            withStock === false
              ? () => '/images/inpulse/add-black-small.svg'
              : () => '/images/inpulse/delete-black-small.svg',
          handleAction: () =>
            this.setItemsValue(this.state.selectedSupplierProducts, 'hasStock', !withStock),
        },
        {
          id: 'add-remove-loss',
          displayActionInLine: false,
          actionLabel:
            withLoss === false
              ? i18next.t('ADMIN.STORES.ADD_TO_LOSS_ACTION')
              : i18next.t('ADMIN.STORES.REMOVE_FROM_LOSS_ACTION'),
          actionIcon:
            withLoss === false
              ? () => '/images/inpulse/add-black-small.svg'
              : () => '/images/inpulse/delete-black-small.svg',
          handleAction: () =>
            this.setItemsValue(this.state.selectedSupplierProducts, 'hasDlc', !withLoss),
        },
      );
      return this.setState({ actions });
    } else {
      actions.push(
        {
          id: 'delete',
          displayActionInLine: userHasRightToUpdateAllSelectedItems,
          actionLabel: i18next.t('GENERAL.DELETE'),
          handleAction: () => this.deleteItem(this.state.selectedSupplierProducts),
          render: this.renderDelete,
          isHidden: () => !userHasRightToUpdateAllSelectedItems,
        },
        {
          id: 'order-set-available',
          displayActionInLine: false,
          actionLabel: i18next.t('ADMIN.STORES.ORDER_SET_AVAILABLE_ACTION'),
          actionIcon: () => '/images/inpulse/power-black-small.svg',
          handleAction: () =>
            this.setItemsValue(this.state.selectedSupplierProducts, 'available', true),
        },
        {
          id: 'order-set-not-available',
          displayActionInLine: false,
          actionLabel: i18next.t('ADMIN.STORES.ORDER_SET_NOT_AVAILABLE_ACTION'),
          actionIcon: () => '/images/inpulse/power-black-small.svg',
          handleAction: () =>
            this.setItemsValue(this.state.selectedSupplierProducts, 'available', false),
        },
        {
          id: 'add-to-stock',
          displayActionInLine: false,
          actionLabel: i18next.t('ADMIN.STORES.ADD_TO_STOCK_ACTION'),
          actionIcon: () => '/images/inpulse/add-black-small.svg',
          handleAction: () =>
            this.setItemsValue(this.state.selectedSupplierProducts, 'hasStock', true),
        },
        {
          id: 'remove-from-stock',
          displayActionInLine: false,
          actionLabel: i18next.t('ADMIN.STORES.REMOVE_FROM_STOCK_ACTION'),
          actionIcon: () => '/images/inpulse/delete-black-small.svg',
          handleAction: () =>
            this.setItemsValue(this.state.selectedSupplierProducts, 'hasStock', false),
        },
        {
          id: 'add-to-loss',
          displayActionInLine: false,
          actionLabel: i18next.t('ADMIN.STORES.ADD_TO_LOSS_ACTION'),
          actionIcon: () => '/images/inpulse/add-black-small.svg',
          handleAction: () =>
            this.setItemsValue(this.state.selectedSupplierProducts, 'hasDlc', true),
        },
        {
          id: 'remove-from-loss',
          displayActionInLine: false,
          actionLabel: i18next.t('ADMIN.STORES.REMOVE_FROM_LOSS_ACTION'),
          actionIcon: () => '/images/inpulse/delete-black-small.svg',
          handleAction: () =>
            this.setItemsValue(this.state.selectedSupplierProducts, 'hasDlc', false),
        },
      );
      return this.setState({ actions });
    }
  };

  renderDelete = () => (
    <Button
      buttonCustomStyle={{ minWidth: 110, fontWeight: 600 }}
      color={'blue-outline-no-shadow'}
      fontSize={14}
      formatText={false}
      handleClick={() => this.deleteItem(this.state.selectedSupplierProducts)}
      icon={'/images/inpulse/delete-black-small.svg'}
      label={i18next.t('GENERAL.DELETE')}
    />
  );

  renderAssociate = () => (
    <Button
      buttonCustomStyle={{ minWidth: 110, fontWeight: 600 }}
      color={'blue'}
      fontSize={14}
      formatText={false}
      handleClick={() =>
        this.props.history.push({
          pathname: '/admin/stores/' + this.state.storeId + '/supplier-products/create',
        })
      }
      icon={'/images/inpulse/add-white-small.svg'}
      label={i18next.t('GENERAL.ASSOCIATE')}
    />
  );

  renderExport = () => {
    const { selectedSupplierProducts, filteredSupplierProductsList } = this.state;

    const data = selectedSupplierProducts.length
      ? selectedSupplierProducts
      : filteredSupplierProductsList;

    return (
      <Button
        buttonCustomStyle={{ minWidth: 100, fontWeight: 600 }}
        color={'blue-outline-no-shadow'}
        fontSize={14}
        formatText={false}
        handleClick={() => this.handleExport(data)}
        icon={'/images/inpulse/file-download-black-small.svg'}
        iconCustomStyle={{ width: '18px', height: '18px' }}
        label={i18next.t('GENERAL.EXPORT')}
      />
    );
  };

  updateColumnsFilterList = (supplierProducts) => {
    const columnsList = this.state.dataTitles.filter((column) =>
      ['date', 'string', 'numeric'].includes(column.filterType),
    );

    const updateColumnsList = columnsList.reduce((result, column) => {
      const updatedColumn = { ...column };

      if (['brand', 'category', 'subCategory', 'supplierName'].includes(column.propertyKey)) {
        const list = Object.values(
          supplierProducts.reduce((result, product, key) => {
            if (product[column.propertyKey]) {
              result[product[column.propertyKey]] = {
                id: key,
                name: product[column.propertyKey],
                status: product[column.propertyKey],
                value: product[column.propertyKey],
                [column.propertyKey]: product[column.propertyKey],
              };
            }

            return result;
          }, {}),
        );

        updatedColumn.list = sortArrayOfObjectsAlphabetically(list, 'name');
      }

      result.push(updatedColumn);

      return result;
    }, []);

    this.setState({ columnsFilterList: updateColumnsList.length ? updateColumnsList : null });
  };

  handleStoreSupplierProductMappingDetails(item) {
    this.props.history.push({
      pathname:
        '/admin/stores/' + this.state.storeId + '/supplier-products/' + item.id + '/details',
    });
  }

  reloadMappings = () => {
    this.props.getStoreSupplierProductMappingsOfStore(
      this.state.storeId,
      moment().startOf('day').format(DATE_DISPLAY_FORMATS.DASHED_YEAR_MONTH_DAY),
      true,
    );
  };

  preProcessStoreSupplierProductMappings(storeSupplierProductMappings) {
    const storeName =
      storeSupplierProductMappings.length > 0
        ? storeSupplierProductMappings[0].lnkStoreStoresupplierproductmappingrel.name
        : '';
    const cleanedStoreSupplierProductMappings = storeSupplierProductMappings.map(
      (storeSupplierProductMapping) => {
        const cleanedStoreSupplierProductMapping = {
          hasDlc: storeSupplierProductMapping.hasDlc,
          hasStock: storeSupplierProductMapping.hasStock,
          available: storeSupplierProductMapping.available,
        };
        const supplierProduct =
          storeSupplierProductMapping.lnkSupplierproductStoresupplierproductmappingrel;
        cleanedStoreSupplierProductMapping.id = storeSupplierProductMapping.id;
        cleanedStoreSupplierProductMapping.supplierId = supplierProduct.supplierId;
        cleanedStoreSupplierProductMapping.name = supplierProduct.name;
        cleanedStoreSupplierProductMapping.category = supplierProduct.category
          ? supplierProduct.category
          : 'Non definie';
        cleanedStoreSupplierProductMapping.subCategory = supplierProduct.subCategory
          ? supplierProduct.subCategory
          : 'Non definie';
        cleanedStoreSupplierProductMapping.price = supplierProduct.price;
        cleanedStoreSupplierProductMapping.hasStockOrder = storeSupplierProductMapping.hasStock
          ? 'Oui'
          : 'Non';
        cleanedStoreSupplierProductMapping.availability = storeSupplierProductMapping.available
          ? 'Oui'
          : 'Non';
        cleanedStoreSupplierProductMapping.hasDlcLoss = storeSupplierProductMapping.hasDlc
          ? 'Oui'
          : 'Non';

        this.props.suppliers.forEach((elem) => {
          if (cleanedStoreSupplierProductMapping.supplierId === elem.id)
            cleanedStoreSupplierProductMapping.supplierName = elem.name;
        });
        cleanedStoreSupplierProductMapping.supplierName =
          cleanedStoreSupplierProductMapping.supplierName || '';
        return cleanedStoreSupplierProductMapping;
      },
    );
    this.setState({
      storeSupplierProductMappings: sortArrayOfObjectsAlphabetically(
        cleanedStoreSupplierProductMappings,
        'name',
      ),
      storeName: storeName,
    });

    this.updateColumnsFilterList(cleanedStoreSupplierProductMappings);

    this.processAdvancedFilter(cleanedStoreSupplierProductMappings);
  }

  componentDidMount() {
    this.setState({ storeId: this.props.storeParams.id });
    this.updateListActions();
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.storeParams !== this.props.storeParams) {
      this.setState({ storeId: this.props.storeParams.id });
    }

    if (prevState.storeId !== this.state.storeId) {
      this.props.getStoreSupplierProductMappingsOfStore(
        this.state.storeId,
        moment().startOf('day').format(DATE_DISPLAY_FORMATS.DASHED_YEAR_MONTH_DAY),
        true,
      );
    }

    if (prevProps.storeSupplierProductMappings !== this.props.storeSupplierProductMappings) {
      this.props.getSuppliersOfClient(this.props.client.clientId).then(() => {
        this.preProcessStoreSupplierProductMappings(this.props.storeSupplierProductMappings);
      });
    }

    if (prevState.selectedSupplierProducts !== this.state.selectedSupplierProducts) {
      this.updateListActions();
    }

    if (
      prevState.advancedFilters !== this.state.advancedFilters ||
      prevState.applyFilters !== this.state.applyFilters
    ) {
      this.preProcessStoreSupplierProductMappings(this.props.storeSupplierProductMappings);
    }
  }

  processAdvancedFilter = (supplierProducts) => {
    if (!this.state.applyFilters) {
      return;
    }

    const filteredSupplierProductsList = supplierProducts.filter(
      (supplierProduct) =>
        this.state.activeKeysForAvailabilityDropdown.some(
          (key) => key.itemValue === !!supplierProduct.available,
        ) &&
        this.state.activeKeysForStockDropdown.some(
          (key) => key.itemValue === !!supplierProduct.hasStock,
        ) &&
        this.state.activeKeysForDlcLossDropdown.some(
          (key) => key.itemValue === !!supplierProduct.hasDlc,
        ),
    );

    if (
      (!this.state.advancedFilters || !this.state.advancedFilters.length) &&
      this.state.applyFilters
    ) {
      this.setState({ filteredSupplierProductsList });
      setTimeout(() => this.setState({ isLoading: false }), 1);
      return;
    }

    const filteredSupplierProductsListWithAdvancedFilters = this.state.advancedFilters.reduce(
      (result, { doFilter, list, filterType, propertyKey, value }) => {
        if (filterType === 'string' && value && value.length === 1 && value[0].id === 'none') {
          return doFilter(result, propertyKey, list, true);
        }

        return doFilter(result, propertyKey, value);
      },
      filteredSupplierProductsList,
    );

    this.setState({
      filteredSupplierProductsList: filteredSupplierProductsListWithAdvancedFilters,
    });
    this.setState({ isLoading: false });
  };

  setItemsValue = (items, field, value) => {
    const sspmIds = items.map(({ id }) => id);

    storeSupplierProductMapping
      .patchStoreSupplierProductMappingByIds(sspmIds, { [field]: value })
      .then(() => {
        this.reloadMappings();
      })
      .catch(() =>
        this.props.showMessage(
          i18next.t('ADMIN.STORES.SUPPLIER_PRODUCT_MAPPING_GENERAL_ERROR'),
          'error',
        ),
      );
  };

  getValue(id, array, field) {
    let res;
    array.forEach((elem) => {
      if (elem.name === id) {
        res = elem[field];
      }
    });
    return res;
  }

  deleteItem = (items) => {
    let mappingIds = items.map((item) => item.id);
    this.props
      .deleteMultipleStoreSupplierProductMapping(mappingIds)
      .then(() => {
        let message;
        mappingIds.length === 1
          ? (message = i18next.t(
              'ADMIN.STORES.SUPPLIER_PRODUCT_MAPPING_DELETION_SUCCESS_SINGUURAL',
            ))
          : (message = i18next.t('ADMIN.STORES.SUPPLIER_PRODUCT_MAPPING_DELETION_SUCCESS_PLURAL'));
        this.props.showMessage(message);
        this.props.getStoreSupplierProductMappingsOfStore(
          this.state.storeId,
          moment().startOf('day').format(DATE_DISPLAY_FORMATS.DASHED_YEAR_MONTH_DAY),
          true,
        );
      })
      .catch(() =>
        this.props.showMessage(
          i18next.t('ADMIN.STORES.SUPPLIER_PRODUCT_MAPPING_GENERAL_ERROR'),
          'error',
        ),
      );
  };

  renderLabel = (item, marginLeft) => (
    <div className="supplier-label-render" style={{ padding: '5px 5px' }}>
      <InpulseLabel
        color={this.getValue(item, STATUS, 'inpulseColor')}
        text={item}
        type={'tertiary'}
      />
    </div>
  );

  render() {
    const {
      filters,
      actions,
      isLoading,
      dataTitles,
      applyFilters,
      advancedFilters,
      columnsFilterList,
      filteredSupplierProductsList,
      choicesForAvailabilityDropdown,
      activeKeysForAvailabilityDropdown,
      choicesForStockDropdown,
      activeKeysForStockDropdown,
      choicesForDlcLossDropdown,
      activeKeysForDlcLossDropdown,
    } = this.state;

    const { listViewQueryParams, setListViewQueryParams } = this.props;

    const userLanguageCode = get(this.props.user, 'lnkLanguageAccountrel.code', 'fr');

    return (
      <ListView
        actionOnClick={(item) => this.handleStoreSupplierProductMappingDetails(item)}
        actions={actions}
        columns={dataTitles}
        data={filteredSupplierProductsList}
        defaultCurrentPage={listViewQueryParams[ENUM_QUERY_PARAMS.CURRENT_PAGE]}
        defaultMaxPerPage={listViewQueryParams[ENUM_QUERY_PARAMS.MAX_PER_PAGE]}
        defaultOrderBy={listViewQueryParams[ENUM_QUERY_PARAMS.ORDER_BY]}
        defaultOrderType={listViewQueryParams[ENUM_QUERY_PARAMS.ORDER_TYPE]}
        defaultSearchInput={listViewQueryParams[ENUM_QUERY_PARAMS.SEARCH]}
        handleCurrentPageChange={(input) =>
          setListViewQueryParams[ENUM_QUERY_PARAMS.CURRENT_PAGE](input)
        }
        handleMaxPerPageChange={(input) =>
          setListViewQueryParams[ENUM_QUERY_PARAMS.MAX_PER_PAGE](input)
        }
        handleOrderByChange={(input) => setListViewQueryParams[ENUM_QUERY_PARAMS.ORDER_BY](input)}
        handleOrderTypeChange={(input) =>
          setListViewQueryParams[ENUM_QUERY_PARAMS.ORDER_TYPE](input)
        }
        handleSearchInputChange={(input) => setListViewQueryParams[ENUM_QUERY_PARAMS.SEARCH](input)}
        isLoading={isLoading}
        languageCode={userLanguageCode}
        padding={'108px 24px 0px 24px'}
        placeholderShape={i18next.t('GENERAL.SEARCH')}
        renderEmptyState={() => <EmptyState />}
        renderFilterButton={() => (
          <DeepsightFiltersButton
            advancedFilters={advancedFilters}
            applyFilters={applyFilters}
            columnsFilterList={columnsFilterList}
            customMultipleDropDowns={[
              {
                id: 'availablility-supplier-products',
                icon: '/images/icon-dropdown-grey.svg',
                list: choicesForAvailabilityDropdown,
                defaultSelectedItems: choicesForAvailabilityDropdown,
                selectedItems: activeKeysForAvailabilityDropdown,
                setSelectedItems: (selectedItems) =>
                  this.setState({ activeKeysForAvailabilityDropdown: selectedItems }),
              },
              {
                id: 'stock-supplier-products',
                icon: '/images/icon-dropdown-grey.svg',
                list: choicesForStockDropdown,
                defaultSelectedItems: choicesForStockDropdown,
                selectedItems: activeKeysForStockDropdown,
                setSelectedItems: (selectedItems) =>
                  this.setState({ activeKeysForStockDropdown: selectedItems }),
              },
              {
                id: 'dlc-loss-supplier-products',
                icon: '/images/icon-dropdown-grey.svg',
                list: choicesForDlcLossDropdown,
                defaultSelectedItems: choicesForDlcLossDropdown,
                selectedItems: activeKeysForDlcLossDropdown,
                setSelectedItems: (selectedItems) =>
                  this.setState({ activeKeysForDlcLossDropdown: selectedItems }),
              },
            ]}
            filters={filters}
            isLoading={isLoading}
            minWidth={120}
            readOnly={isLoading}
            setAdvancedFilters={(it) => {
              this.setState({ advancedFilters: it });
            }}
            setApplyFilters={(it) => this.setState({ applyFilters: it })}
            setFilters={(it) => this.setState({ filters: it })}
            textFilterButton={i18next.t('GENERAL.LIST_VIEW_FILTER_BUTTON')}
          />
        )}
        setSelectedItems={(items) =>
          this.setState({
            selectedSupplierProducts: items,
          })
        }
      />
    );
  }
}

const mapStateToProps = (state) => ({
  storeSupplierProductMappings: state.baseReducer.storeSupplierProductMappings,
  suppliers: state.baseReducer.suppliers,
  user: state.baseReducer.user,
  currency: state.baseReducer.currency,
  client: getClientInfo(state.baseReducer.user),
});

const mapDispatchToProps = (dispatch) => ({
  showMessage: (message, type) => {
    dispatch(showConfirmationMessage(message, type));
  },
  getStoreSupplierProductMappingsOfStore: (storeId, date, filterByUserCatalog) =>
    dispatch(getStoreSupplierProductMappingsOfStore(storeId, date, filterByUserCatalog)).then(
      (result) => {
        dispatch(receiveStoreSupplierProductMappings(result));
      },
      (error) => {
        dispatch(requestStoreSupplierProductMappingsError(error));
      },
    ),
  getSuppliersOfClient: (clientId) =>
    dispatch(getSuppliersOfClient(clientId)).then(
      (result) => dispatch(receiveSuppliers(result)),
      (error) => dispatch(requestSuppliersError(error)),
    ),
  deleteMultipleStoreSupplierProductMapping: (mappingIds) =>
    dispatch(deleteMultipleStoreSupplierProductMapping(mappingIds)).then(
      (result) => dispatch(receiveDeleteMultipleStoreSupplierProductMapping(result)),
      (error) => {
        dispatch(requestDeleteMultipleStoreSupplierProductMappingError(error));
        throw 'Error';
      },
    ),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withListViewQueryParamHookHOC(StoreSupplierProductMapping));
